@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.button-with-icon {
  display: flex;
  border: solid 1px var(--color-background);
  border-radius: 4px;
  cursor: pointer;
  margin: 12px auto;
  max-width: 334px;
  padding: 24px 22px 24px 24px;
  align-items: center;
  text-align: start;
  transition: $link-hover-transition;

  &:is(a) {
    text-decoration: none;
    color: var(--color-primary);

    @include hover {
      color: var(--color-secondary-variation);
    }
  }

  &:is(button) {
    appearance: none;
    background-color: transparent;
    width: 100%;
    color: var(--color-primary);
  }

  &:focus-visible {
    outline-color: var(--color-secondary-variation);
    outline-width: 4px;
    outline-offset: -2px;
  }

  @include bp.respond-from('lg') {
    transition: border-color $transition;
    height: 95px;

    @include hover {
      border-color: var(--color-secondary);
    }
  }

  @include bp.respond-until('lg') {
    height: 85px;
  }

  .icon {
    font-size: 24px;
    height: 1em;
    margin-inline-end: 16px;
  }

  sup {
    top: -0.7em;
    font-size: 7px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-inline-start: 1ch;
    text-transform: uppercase;
  }

  b {
    display: block;
  }
}
