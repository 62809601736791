.expert-panel {
  &__image {
    margin: 100px auto 0;
    height: 104px;
    width: 104px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  h2 {
    margin: 12px auto 32px;
    text-align: center;
  }
}
